
import { defineComponent, computed, onMounted } from 'vue'
import checkUserSettings from '@/mixins/checkUserSettings'
import toggleableContent from '@/components/toggleableContent.vue'
import { mandatoryAccessoriesType, step2ErrorsType } from '@/types'
import RadioButton from '@/components/UI/RadioButton.vue'
import formatCurrency from '@/filters/CurrencyFormat'
import Button from '@/components/UI/Button.vue'
import BackButton from '@/components/UI/BackButton.vue'
import OptionsCheckboxes from '@/components/UI/OptionsCheckboxes.vue'
import Loader from '@/components/UI/Loader.vue'
import { VueCookieNext } from 'vue-cookie-next'
import { AuthActionTypes } from '@/store/auth/actions'
import { ActionTypes } from '@/store/options/actions'
import { MutationTypes } from '@/store/options/mutations'
import { useStore } from '@/store'
import { reactive, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import Popup from '@/components/Popup.vue'
import AddChargingFacilities from '@/components/AddChargingFacilities.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import { useI18n } from 'vue-i18n'
import { USER_STATUS } from '@/composables/staticValues'
import toLowerCase from '@/filters/toLowerCase'

export default defineComponent({
  components: {
    toggleableContent,
    RadioButton,
    Button,
    BackButton,
    OptionsCheckboxes,
    Loader,
    Popup,
    AddChargingFacilities,
    Dropdown
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const componentKey = ref(0)
    const requiredChoiceIndex = ref(0)
    const showPopup = ref(false)
    const showCarOrderPopup = ref(false)
    const selectedCheckbox = ref('')
    const packMembers = ref()
    const updateCarOrderValue = ref(1)
    const carOrderStatus = ref(0)
    const isExpensiveFlag = ref(true)
    const isWheelFlag = ref(true)
    const showConflictedOptionsPopup = ref(false)
    const showConflictedNestedOptionsPopup = ref(false)
    const { t } = useI18n({ useScope: 'global' })
    const packMembersPopUpTitle = ref(t('_step3.itemsAdded'))
    const optionsResponse = computed(() => store.state.options.optionsResponse)
    const optionsResponseAsObject = computed(() => store.state.options.optionsResponseAsObject)
    const nestedOptions = computed(() => store.state.options.nestedOptions)
    const stateVehicleId = computed(() => store.state.options.vehicleId)
    const { displayUserSettingPopup, goToAccount } = checkUserSettings()
    const exteriorInterior = reactive({
      exteriorOptionC: [],
      exteriorOptionO: [],
      interiorOptionC: [],
      interiorOptionO: []
    })
    const others: { value: mandatoryAccessoriesType[] } = reactive({
      value: [
        { accessory: '', price: '', type: 'add' }
      ]
    })
    const convertToNumberAndFloat = (selectedOption) => {
      return selectedOption.replace(/,/g, '.')
    }
    const removeType = (type) => {
      const tempFacility = JSON.parse(JSON.stringify(type))
      tempFacility.forEach((item) => {
        delete item['type']
        if (!item.accessory || !item.price) {
          delete item['accessory']
          delete item['price']
        }
        if (item['price']) item['price'] = convertToNumberAndFloat(item['price'])
      })
      return tempFacility.filter((item) => !(item && Object.keys(item).length === 0))
    }
    const showComplyPopup = ref(false)
    const closeComplyPopup = () => {
      showComplyPopup.value = false
    }
    const closeCarOrderPopup = () => {
      showCarOrderPopup.value = false
    }
    const selectedOptions: Array<string> = []
    let optionsConfigurationData = sessionStorage.getItem('optionsConfigurationData')
      ? JSON.parse(sessionStorage.getItem('optionsConfigurationData') || '{}')
      : {
        selectedOptions: selectedOptions,
        requiredChoices: [],
        option: '',
        action: '',
        otherAccessories: [],
        chargingFacilities: [],
        userSelectedOptions: []
      }
    const isColorsPopup = ref(false)
    const initialize = async () => {
      // if new vehicleId entered and optionsConfiguration will be cleared
      if (sessionStorage.getItem('currentVehicleId')) {
        const getCurrentVehicleID = JSON.parse(sessionStorage.getItem('currentVehicleId'))
        if (getCurrentVehicleID && getCurrentVehicleID !== route.params.vehicleId) {
          optionsConfigurationData = {
            selectedOptions: selectedOptions,
            requiredChoices: [],
            option: '',
            action: '',
            otherAccessories: [],
            chargingFacilities: [],
            userSelectedOptions: []
          }
          sessionStorage.removeItem('nestedOptions')
          sessionStorage.removeItem('latestSelection')
        }
      }
      // 43366520210701
      // 809814620210101
      // 786165220210401
      // 433666
      sessionStorage.setItem('optionsConfigurationData', JSON.stringify(optionsConfigurationData))
      await store.dispatch(ActionTypes.GET_OPTIONS_CONFIGURATIONS, {
        vehicleId: route.params.vehicleId,
        selectedOptions: optionsConfigurationData,
        selectedCheckbox: selectedCheckbox.value,
        packMembers: packMembers.value,
      })
      if (document.getElementById('popup')) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
      isExpensiveFlag.value = true
      isWheelFlag.value = true
      isColorsPopup.value = false
      // if new vehicleId entered
      if (stateVehicleId.value)
        sessionStorage.setItem('currentVehicleId', JSON.stringify(stateVehicleId.value))
      // to track the option that just added
      const optionJustAdded = optionsConfigurationData['userSelectedOptions'][optionsConfigurationData['userSelectedOptions'].length - 1]
      sessionStorage.setItem('optionJustAdded', JSON.stringify([optionJustAdded]))
      if (!optionJustAdded) {
        sessionStorage.removeItem('optionJustAdded')
      }
      // check if any of the nestedOption is conflicted
      const getNestedOptions = Object.entries(nestedOptions.value)
      const allOptions = getNestedOptions.flat(2)
      const conflictedOptionsDetails = optionsResponse.value
        .filter(item => {
          return allOptions.indexOf(item['optionId'].toString()) !== -1 && item.newState == 'conflicted'
        })
        .map(function (item) {
          return { titleEn: item['titleEn'], titleNl: item['titleNl'], code: item['code'], optionID: item['optionId'].toString(), state: item.newState }
        })
      if (conflictedOptionsDetails.length) {
        showConflictedNestedOptionsPopup.value = true
      }
    }
    const loader = computed(() => store.state.options.optionsloader)
    const options = computed(() => store.state.options.options)
    const packagesAndOptions = computed(() => store.state.options.packagesAndOptions)
    const requiredChoicesTitles = computed(() => store.state.options.requiredChoicesTitles)
    const packMembersTitles = computed(() => store.state.options.packMembersTitles)
    const carPolicyId = computed(() => store.state.auth.carPolicyByID)
    const currentUserCarOrder = computed(() => store.state.auth.currentUser.carOrders)
    const complyCarResponse = computed(() => store.state.options.complyCarResponse)
    const getCarsById = computed(() => store.state.options.carsById.cars)
    const carType = computed(() => store.state.options.carsById.cars['type_48602'] || store.state.options.carsById.cars['fuel_type_8702'])
    const carOrderId = ref(route.params.carOrderId)
    const carOrderedTrue = computed(() => toLowerCase(store.state.auth.currentUser.userStatus) === USER_STATUS.CAR_ORDERED)
    const NetMonthlyCostAndFlag = computed(() => store.state.options.NetMonthlyCostAndFlag)
    const colorsFlag = computed(() => store.state.options.colorsFlag)
    const allSelectedOptions = computed(() => store.state.options.allSelectedOptions)
    const latestSelection = computed(() => store.state.options.latestSelection)
    const leaseRateError = computed(() => store.state.options.leaseRateError)
    const conflictedOptions = computed(() => store.state.options.conflictedOptions)
    const colorsMessage = computed(() => {
      let errMessage = ''
      if (!colorsFlag.value.interior && !colorsFlag.value.exterior) {
        errMessage = t('_step3.selectBothColor')
      } else if (colorsFlag.value.interior && !colorsFlag.value.exterior) {
        errMessage = t('_step3.selectExteriorColor')
      } else {
        errMessage = t('_step3.selectInteriorColor')
      }
      return errMessage
    })
    const selectedChargingFacility = ref()
    const carOrderAdd = reactive({
      vehicleId: route.params.vehicleId,
      monthlyCost: 13.74,
      carPrice: 12.25,
      chargingFacilities: [] as mandatoryAccessoriesType[]
    })
    const revertSelectedOptions = async (state) => {
      let latestSelection
      if (state == 'conflictedRequiredOptions')
        latestSelection = JSON.parse(sessionStorage.getItem('latestSelection'))
      else if (state == 'conflictedNestedOptions')
        latestSelection = JSON.parse(sessionStorage.getItem('optionJustAdded'))
      latestSelection.forEach((item) => {
        delete nestedOptions.value[item]
        const getNestedOptions = Object.entries(nestedOptions.value)
        optionsConfigurationData['selectedOptions'] = getNestedOptions.flat(2)
        if (optionsConfigurationData['userSelectedOptions'].indexOf(item) != -1)
          optionsConfigurationData['userSelectedOptions'].splice(optionsConfigurationData['userSelectedOptions'].indexOf(item), 1)
      })
      optionsConfigurationData['action'] = ''
      optionsConfigurationData['option'] = ''
      await initialize()
      if (state == 'conflictedRequiredOptions')
        store.state.options.latestSelection = []
      else if (state == 'conflictedNestedOptions')
        showConflictedNestedOptionsPopup.value = false
      document.body.classList.remove('modal-open')
      sessionStorage.removeItem('optionJustAdded')
    }
    const onPopupClose = () => {
      const selected = document.querySelector('input[type=radio][name=name]:checked')
      if (selected) {
        const selectedID = selected.id.replace('radio-', '')
        store.state.options.latestSelection.push(selectedID)
      }
      if (sessionStorage.getItem('latestSelection')) {
        revertSelectedOptions('conflictedRequiredOptions')
      }
      showPopup.value = false
      store.commit(MutationTypes.SET_REQUIRED_CHOICES_TITLES, [])
      store.commit(MutationTypes.SET_PACK_MEMBERS_TITLES, [])
      document.body.classList.remove('modal-open')
    }
    const closeExpensivePopup = () => {
      isExpensiveFlag.value = false
      isWheelFlag.value = false
      document.body.classList.remove('modal-open')
    }
    const onClose = () => {
      showPopup.value = false
      store.commit(MutationTypes.SET_PACK_MEMBERS_TITLES, [])
      document.body.classList.remove('modal-open')
      packMembers.value = ''
    }
    const getExteriorInterior = (prop, type) => {
      let filteredExteriorInterior
      if (options.value) {
        const data = options.value?.[prop]
        filteredExteriorInterior = data?.filter((item) => item['type'] == type)
      }
      return filteredExteriorInterior
    }
    const groupByOptionsType = () => {
      const filteredExteriorOptionC = getExteriorInterior('exteriorColour', 'C')
      const filteredExteriorOptionO = getExteriorInterior('exteriorColour', 'O')
      const filteredInteriorOptionC = getExteriorInterior('interiorColour', 'C')
      const filteredInteriorOptionO = getExteriorInterior('interiorColour', 'O')
      exteriorInterior.exteriorOptionC = filteredExteriorOptionC
      exteriorInterior.exteriorOptionO = filteredExteriorOptionO
      exteriorInterior.interiorOptionC = filteredInteriorOptionC
      exteriorInterior.interiorOptionO = filteredInteriorOptionO
    }
    const getCarPolicyById = async () => {
      await store.dispatch(AuthActionTypes.GET_CARPOLICY_BY_ID, {
        companyId: VueCookieNext.getCookie('companyId'),
        carPolicyId: VueCookieNext.getCookie('employeeCarPolicyId')
      })
    }
    const otherAccessoriesErrors: Array<step2ErrorsType> = reactive([{
      facilityError: '',
      priceError: ''
    }])
    onMounted(async () => {
      if (!displayUserSettingPopup.value) {
        await initialize()
        await groupByOptionsType()
        await getCarPolicyById()
        selectedChargingFacility.value = carPolicyId.value?.chargingFacility[0]
        if (optionsConfigurationData['chargingFacilities'].length) {
          selectedChargingFacility.value.chargingFacility = optionsConfigurationData['chargingFacilities'][0]['accessory']
          selectedChargingFacility.value.price = optionsConfigurationData['chargingFacilities'][0]['price']
        }
        if (optionsConfigurationData['otherAccessories'].length) {
          Array.prototype.unshift.apply(others.value, optionsConfigurationData['otherAccessories'])
          for (let i = 0; i < optionsConfigurationData['otherAccessories'].length; i++) {
            otherAccessoriesErrors.unshift({
              facilityError: '',
              priceError: ''
            })
          }
        }
      }
    })
    const checkIfEqual = (array1, array2) => {
      if (array1.length === array2.length) {
        return array1.every((element, index) => {
          if (element === array2[index]) {
            return true
          }
          return false
        });
      }
      return false;
    }
    watch(
      () => _.cloneDeep(options.value),
      () => {
        groupByOptionsType()
      }
    )
    watch(
      () => _.cloneDeep(requiredChoicesTitles.value),
      () => {
        requiredChoiceIndex.value = 0
      }
    )
    watch(
      () => route.params.vehicleId,
      () => {
        sessionStorage.removeItem('optionsConfigurationData')
        sessionStorage.removeItem('nestedOptions')
        sessionStorage.removeItem('latestSelection')
        sessionStorage.removeItem('currentVehicleId')
      },
    )
    watch(
      () => _.cloneDeep(allSelectedOptions.value),
      () => {
        const mergedArray = [...new Set([...optionsConfigurationData['selectedOptions'], ...allSelectedOptions.value])];
        if (!checkIfEqual(optionsConfigurationData['selectedOptions'], mergedArray)) {
          optionsConfigurationData['selectedOptions'] = mergedArray
          initialize()
        }
      }
    )
    watch(
      () => _.cloneDeep(latestSelection.value),
      (currentValue) => {
        sessionStorage.setItem('latestSelection', JSON.stringify(currentValue))
        if (!latestSelection.value.length) {
          sessionStorage.removeItem('latestSelection')
        }
      }
    )
    watch(
      () => _.cloneDeep(nestedOptions.value),
      (currentValue) => {
        if (optionsConfigurationData['action'] == 'added' && optionsConfigurationData['option'] && optionsResponse.value.length) {
          const addToNestedOptions = (selectedOptionId, key) => {
            const packMembers = optionsResponseAsObject.value[selectedOptionId]['packMembers']
            const requiredOptions = optionsResponseAsObject.value[selectedOptionId]['required']
            const flattenArray = [...new Set([...packMembers ?? '', ...requiredOptions ?? ''])];
            return flattenArray.forEach((value) => {
              if (store.state.options.nestedOptions[key].indexOf(value) === -1) {
                store.state.options.nestedOptions[key].push(value)
              }
            })
          }
          const userSelectedOptionIds = optionsConfigurationData['userSelectedOptions']
          userSelectedOptionIds.forEach((optionId) => {
            addToNestedOptions(optionId, optionId)
            currentValue[optionId].forEach((nestedOptionId) => {
              addToNestedOptions(nestedOptionId, optionId)
            })
          })
        }
        sessionStorage.setItem('nestedOptions', JSON.stringify(currentValue))
        if (!Object.keys(nestedOptions.value).length) {
          sessionStorage.removeItem('nestedOptions')
        }
      }
    )
    watch(
      () => _.cloneDeep(conflictedOptions.value),
      (currentValue) => {
        if (currentValue.length) {
          showConflictedOptionsPopup.value = true
          currentValue.forEach((item) => {
            optionsConfigurationData['selectedOptions'].splice(optionsConfigurationData['selectedOptions'].indexOf(item['optionID']), 1)
            optionsConfigurationData['userSelectedOptions'].splice(optionsConfigurationData['userSelectedOptions'].indexOf(item['optionID']), 1)
            delete nestedOptions.value[item['optionID']]
          })
          initialize()
        }
      }
    )
    const getFilteredPackageOptions = (prop, type) => {
      let filteredPackagesAndOptions
      if (packagesAndOptions.value) {
        const data = packagesAndOptions.value?.[prop]
        filteredPackagesAndOptions = data?.filter((item) => item['type'] == type)
      }
      return filteredPackagesAndOptions
    }
    const getPackagesAndOptions = (type) => {
      const packageOptions = {}
      for (const key in packagesAndOptions.value) {
        const result = getFilteredPackageOptions(key, type)
        if (result && result.length) {
          packageOptions[key] = result
        }
      }
      return packageOptions
    }
    const setToDefault = () => {
      showPopup.value = true
      requiredChoiceIndex.value = 0
    }
    const checkChargingFacility = (fuelType) => {
      if (fuelType == 'electric' || fuelType == 'plug_in_hybrid') {
        if (carPolicyId.value.fuelType) {
          const checkFuelType = carPolicyId.value.fuelType.some((item) => item.title == fuelType && item.isChargingMandatory == 1)
          return checkFuelType
        }
      } else {
        return false
      }
    }
    const onCheck = async (item) => {
      optionsConfigurationData['option'] = item['option'].target.id
      optionsConfigurationData['action'] = item['option'].target.checked ? 'added' : 'removed'
      if (optionsConfigurationData['selectedOptions'].indexOf(item['option'].target.id) === -1) {
        optionsConfigurationData['selectedOptions'].push(item['option'].target.id)
      }
      if (optionsConfigurationData['userSelectedOptions'].indexOf(item['option'].target.id) === -1) {
        optionsConfigurationData['userSelectedOptions'].push(item['option'].target.id)
      }
      if (!item['option'].target.checked) {
        optionsConfigurationData['selectedOptions'].splice(
          optionsConfigurationData['selectedOptions'].indexOf(item['option'].target.id),
          1
        )
        optionsConfigurationData['userSelectedOptions'].splice(
          optionsConfigurationData['userSelectedOptions'].indexOf(item['option'].target.id),
          1
        )
        packMembersPopUpTitle.value = t('_step3.itemsRemoved')
        // if unchecked removed selectedOptions + packMembers + requiredOptions
        delete nestedOptions.value[optionsConfigurationData['option']]
        const getNestedOptions = Object.entries(nestedOptions.value)
        optionsConfigurationData['selectedOptions'] = getNestedOptions.flat(2)
      } else {
        packMembersPopUpTitle.value = t('_step3.itemsAdded')
      }
      selectedCheckbox.value = item['option'].target.id
      packMembers.value = item.packMembers
      await initialize()
      setToDefault()
      componentKey.value += 1
    }
    const onRadioClick = (item) => {
      const optionId = item.optionId
      selectedCheckbox.value = optionId
      packMembers.value = item.packMembers
    }
    const onProceed = () => {
      const selected = document.querySelector('input[type=radio][name=name]:checked')
      if (selected) {
        const selectedID = selected.id.replace('radio-', '')
        optionsConfigurationData['action'] = 'added'
        optionsConfigurationData['option'] = selectedID
        optionsConfigurationData['selectedOptions'].push(selectedID)
        optionsConfigurationData['userSelectedOptions'].push(selectedID)
        store.state.options.latestSelection.push(selectedID)
        initialize()
      }
      if (requiredChoiceIndex.value < requiredChoicesTitles.value.length - 1) {
        requiredChoiceIndex.value += 1
      }
    }
    const checkConflictedOptions = (options) => {
      const isStateConflicted = options.every(function (item) {
        return item['newState'] == 'conflicted'
      })
      return isStateConflicted
    }
    const getTranslatedCategory = (item) => {
      let category = '_configure.other'
      if (item == 'Safety') {
        category = '_configure.safety'
      } else if (item == 'Driving & braking') {
        category = '_configure.drivingAndBraking'
      } else if (item == 'Audio & Entertainment') {
        category = '_configure.audioEntertainment'
      } else if (item == 'Comfort') {
        category = '_configure.comfort'
      } else if (item == 'Interior colour') {
        category = '_configure.interiorColour'
      } else if (item == 'Exterior colour') {
        category = '_configure.exteriorColour'
      }
      return category
    }
    const getStatus = (item) => {
      return item['newState'] === 'selected' || item['isPack'] == true || item['newState'] === 'required'
    }
    const addToOptionsConfigurations = () => {
      optionsConfigurationData['otherAccessories'] = !others.value[0]['accessory'] ? [] : removeType(others.value)
      initialize()
    }
    const addFacility = (item) => {
      others.value.push({
        accessory: '', price: '', type: 'add'
      })
      others.value[item['index']]['type'] = 'delete'
      otherAccessoriesErrors.push({
        facilityError: '',
        priceError: ''
      })
      addToOptionsConfigurations()
    }
    const deleteFacility = (item) => {
      others.value.splice(item['index'], 1)
      addToOptionsConfigurations()
    }
    const selectChargingFacility = (item) => {
      selectedChargingFacility.value = item
      if (checkChargingFacility(carType.value ?? ''))
        optionsConfigurationData['chargingFacilities'] = [{
          accessory: selectedChargingFacility.value['chargingFacility'],
          price: selectedChargingFacility.value['price']
        }]
      initialize()
    }
    const onNameInput = (option) => {
      others.value[option['index']]['accessory'] = option['item']
      delete otherAccessoriesErrors[option['index']]['facilityError']
      if (others.value[option['index']]['accessory'].length < 2 || others.value[option['index']]['accessory'].length > 250) {
        otherAccessoriesErrors[option['index']]['facilityError'] = 'LengthError'
      }
    }
    const onPriceInput = (option) => {
      others.value[option['index']]['price'] = option['item']
      delete otherAccessoriesErrors[option['index']]['priceError']
      if (!others.value[option['index']]['price'].match(/^-?[0-9.,]*$/)) {
        otherAccessoriesErrors[option['index']]['priceError'] = 'typeError'
      }
    }
    const getFilteredNestedOptions = () => {
      const nestedOptions = JSON.parse(sessionStorage.getItem('nestedOptions'))
      const options = []
      for (const key in nestedOptions) {
        optionsResponse.value
          .forEach((item) => {
            if (item['optionId'].toString() == key) {
              options.push({ titleEn: item['titleEn'], titleNl: item['titleNl'], code: item['code'], optionId: item['optionId'], type: item['type'], price: item['price'], priceExcludingVAT: item['priceExcludingVAT'], category: item['category'], children: nestedOptions[key] })
            }
          })
      }
      options.forEach((option) => {
        const nestedChildern = optionsResponse.value
          .filter((item) => {
            return option.children.indexOf(item['optionId'].toString()) !== -1
          })
          .map(function (item) {
            return { titleEn: item['titleEn'], titleNl: item['titleNl'], code: item['code'], optionId: item['optionId'], type: item['type'], price: item['price'], priceExcludingVAT: item['priceExcludingVAT'], category: item['category'] }
          })
        option.children = nestedChildern
      })
      return options
    }
    const onProceedCarOrder = async () => {
      carOrderAdd['co2Emission'] = getCarsById.value['combined_62203'],
        carOrderAdd['options'] = getFilteredNestedOptions()
      carOrderAdd['otherAccessories'] = !others.value[0]['accessory'] ? [] : removeType(others.value)
      if (checkChargingFacility(carType.value ?? ''))
        carOrderAdd['chargingFacilities'] = [{
          accessory: selectedChargingFacility.value['chargingFacility'],
          price: selectedChargingFacility.value['price']
        }]
      carOrderAdd['status'] = carOrderStatus.value
      if (updateCarOrderValue.value === 1) {
        await store.dispatch(ActionTypes.ADD_CARORDER, { carOrderData: carOrderAdd, id: carOrderId.value })
        if (complyCarResponse.value && complyCarResponse.value.length) {
          showComplyPopup.value = true
        }
      } else {
        await store.dispatch(ActionTypes.ADD_CARORDER, { carOrderData: carOrderAdd })
        if (complyCarResponse.value && complyCarResponse.value.length) {
          showComplyPopup.value = true
        }
      }
    }

    const onCarOrderRadioClick = (item) => {
      updateCarOrderValue.value = item.optionId
    }
    const continueToOrderProcess = async (status) => {
      await initialize()
      if (colorsFlag.value.interior && colorsFlag.value.exterior && !requiredChoicesTitles.value.length && !NetMonthlyCostAndFlag.value.expensiveFlag && !NetMonthlyCostAndFlag.value.wheelFlag ) {
        carOrderStatus.value = status
        if(!carOrderStatus.value && carOrderId.value) {
          showCarOrderPopup.value = true
        } else {
          onProceedCarOrder()
        }
      } else {
        isColorsPopup.value = true
      }
    }

    const closeColorsPopup = () => {
      isColorsPopup.value = false
    }
    const closeConflictedOptionsPopup = () => {
      showConflictedOptionsPopup.value = false
      store.state.options.conflictedOptions = []
      document.body.classList.remove('modal-open')
    }
    const closeConflictedNestedOptionsPopup = () => {
      if (sessionStorage.getItem('optionJustAdded')) {
        revertSelectedOptions('conflictedNestedOptions')
      }
    }
    const enableSelectedOptions = (option) => {
      if ((option['newState'] === 'required' || option['isPack']) && optionsConfigurationData['userSelectedOptions']) {
        if (optionsConfigurationData['userSelectedOptions'].includes(option.optionId.toString())) {
          return false
        }
        return true
      }
    }
    return {
      exteriorInterior,
      options,
      loader,
      getPackagesAndOptions,
      formatCurrency,
      onCheck,
      getTranslatedCategory,
      getStatus,
      requiredChoicesTitles,
      requiredChoiceIndex,
      showPopup,
      onPopupClose,
      onRadioClick,
      onProceed,
      packMembersPopUpTitle,
      packMembersTitles,
      onClose,
      addFacility,
      deleteFacility,
      onNameInput,
      onPriceInput,
      others,
      otherAccessoriesErrors,
      carPolicyId,
      selectedChargingFacility,
      selectChargingFacility,
      currentUserCarOrder,
      complyCarResponse,
      carOrderAdd,
      continueToOrderProcess,
      closeComplyPopup,
      showComplyPopup,
      checkChargingFacility,
      getCarsById,
      closeCarOrderPopup,
      showCarOrderPopup,
      onCarOrderRadioClick,
      onProceedCarOrder,
      componentKey,
      enableSelectedOptions,
      carOrderedTrue,
      NetMonthlyCostAndFlag,
      closeExpensivePopup,
      isExpensiveFlag,
      isWheelFlag,
      closeColorsPopup,
      isColorsPopup,
      colorsMessage,
      checkConflictedOptions,
      revertSelectedOptions,
      carType,
      leaseRateError,
      showConflictedOptionsPopup,
      closeConflictedOptionsPopup,
      conflictedOptions,
      showConflictedNestedOptionsPopup,
      closeConflictedNestedOptionsPopup,
      displayUserSettingPopup,
      goToAccount
    }
  }
})
