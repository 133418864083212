
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['onRadioClick'],
  props: {
    optionId: {
      type: Number,
      default: 0
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    packMembers: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const onRadioClick = (optionId, packMembers) => {
      emit('onRadioClick', { optionId: optionId, packMembers: packMembers })
    }
    return { onRadioClick }
  }
})
